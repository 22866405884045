<template>
  <b-sidebar
    id="terms-of-payment-sidebar"
    title="Условия оплаты"
    right
    backdrop
    shadow
  >
    <template #header="{ hide }">
      Условия оплаты
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <div class="form-terms-of-payment">
        <label class="form-terms-of-payment__label">Условия отплаты</label>
        <div class="form-terms-of-payment__switch-block">
          <b-form-checkbox
            :checked="getDocumentHead.getPaymentActive"
            name="check-button"
            :disabled="getDocumentHead.fixed"
            switch
            @change="cangeIsSwitchActive"
          />
          <div class="form-terms-of-payment__label-switch">
            <div>Из договора</div>
            <img
              v-b-tooltip.hover.right
              title="Значение будет взято из договора"
              src="/img/icons/contract/info.svg"
              alt="search"
            />
          </div>
        </div>

        <div class="form-terms-of-payment__radio-block">
          <e-radio
            :checked="getDocumentHead.getPaymentTerm === 'AFTER_FIX'"
            :disabled="getDocumentHead.getPaymentActive || getDocumentHead.fixed"
            @click="setTermsPayment('AFTER_FIX')"
          />
          <div>От даты поставки</div>
        </div>

        <div class="form-terms-of-payment__radio-block">
          <e-radio
            :checked="getDocumentHead.getPaymentTerm === 'AFTER_SUPPLIER_DATE'"
            :disabled="getDocumentHead.getPaymentActive || getDocumentHead.fixed"
            @click="setTermsPayment('AFTER_SUPPLIER_DATE')"
          />
          <div>От даты документа поставщика</div>
        </div>

        <div class="form-terms-of-payment__radio-block">
          <e-radio
            :checked="getDocumentHead.getPaymentTerm === 'AFTER_SELL'"
            :disabled="getDocumentHead.getPaymentActive || getDocumentHead.fixed"
            @click="setTermsPayment('AFTER_SELL')"
          />
          <div>От реализации</div>
        </div>

        <div class="form-terms-of-payment__input-postponement-days-block">
          <label class="form-terms-of-payment__label">Отсрочка, Дней</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getPaymentDueDate ?? '-' }}</div>
          <e-input
            v-else
            :value="getDocumentHead.getPaymentDueDate"
            class="form-terms-of-payment__input"
            placeholder=""
            type="number"
            :disabled="getDocumentHead.getPaymentActive"
            @change="changePostponementDays"
          />
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex/dist/vuex.esm.browser'
  export default {
    name: 'TermsOfPaymentSidebar',

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead'
      })
    },

    methods: {
      ...mapMutations({
        SET_DOCUMENR_PARAM: 'contracts/SET_DOCUMENR_PARAM'
      }),

      async createTreatyPayment() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../../gql/CreateTreatyPayment.gql'),
            debounce: 500,
            variables: {
              input: this.getDocumentHead.inputTreatyPayment
            }
          })
          this.$emit('refetch')
        } catch (err) {
          console.log(err)
        }
      },

      changePostponementDays(text) {
        this.SET_DOCUMENR_PARAM({ key: 'payment_due_date', value: +text })
        this.createTreatyPayment()
      },

      setTermsPayment(value) {
        this.SET_DOCUMENR_PARAM({ key: 'payment_term', value: value })
        this.createTreatyPayment()
      },

      cangeIsSwitchActive() {
        this.SET_DOCUMENR_PARAM({ key: 'payment_active', value: !this.getDocumentHead.getPaymentActive })
        this.createTreatyPayment()
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-terms-of-payment {
    padding: 22px;

    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__switch-block {
      display: flex;
      align-items: center;
    }

    &__label-switch {
      display: flex;
      align-items: center;
      gap: 7px;
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__radio-block {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 12px;
    }

    &__input-postponement-days-block {
      margin-top: 16px;
      width: 91px;
    }
  }

  ::v-deep() {
    #terms-of-payment-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }
</style>
