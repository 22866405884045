export class FuturePriceModel {
  constructor(request = {}) {
    this.price = request?.price
    this.begin = request?.begin ? new Date(request?.begin) : new Date()
  }

  get getPrice() {
    return this.price
  }

  get getBegin() {
    return this.begin ? this.begin : new Date()
  }

  setPrice(price) {
    this.price = price
  }

  setBegin(date) {
    this.begin = date
  }
}
