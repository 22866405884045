<template>
  <b-sidebar
    id="delivery-conditions-sidebar"
    title="Условия поставки"
    right
    backdrop
    shadow
  >
    <template #header="{ hide }">
      Условия поставки
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <div class="form-delivery-conditions">
        <h4 class="form-delivery-conditions__title-block">Контакт</h4>

        <div class="form-delivery-conditions__input-block">
          <label class="form-delivery-conditions__label">ФИО</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getContactName }}</div>
          <e-input
            v-else
            :value="getDocumentHead.getContactName"
            class="form-delivery-conditions__input"
            placeholder="Введите ФИО"
            type="text"
            @change="cangeContactName"
          />
        </div>

        <div class="form-delivery-conditions__flex-input-block">
          <div class="form-delivery-conditions__input-block">
            <label class="form-delivery-conditions__label">Номер телефона</label>
            <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getContactPhone }}</div>
            <b-form-input
              v-else
              v-mask="'+7##########'"
              :value="getDocumentHead.getContactPhone"
              placeholder="+7XXXXXXXXXX"
              type="text"
              required
              @input="changePhone"
            />
          </div>

          <div class="form-delivery-conditions__input-block">
            <label class="form-delivery-conditions__label">Email</label>
            <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getContactEmail }}</div>
            <e-input
              v-else
              :value="getDocumentHead.getContactEmail"
              class="form-delivery-conditions__input"
              placeholder="Email"
              type="email"
              @change="changeEmail"
            />
          </div>
        </div>

        <div class="block-separator"></div>

        <div class="form-delivery-conditions__block-delivery-title">
          <div class="form-delivery-conditions__delivery-title">Условия поставщика</div>

          <div class="form-delivery-conditions__switch-block">
            <b-form-checkbox
              :checked="getDocumentHead.getDeliveryActive"
              name="check-button"
              :disabled="getDocumentHead.fixed"
              switch
              @change="cangeIsSwitchActive"
            />
            <div class="form-delivery-conditions__label-switch">
              <div>Из договора</div>
              <img
                v-b-tooltip.hover.top
                title="Значение будет взято из договора"
                src="/img/icons/contract/info.svg"
                alt="search"
              />
            </div>
          </div>
        </div>

        <div class="form-delivery-conditions__delivery-container">
          <h4 class="form-delivery-conditions__title-block">График заказов</h4>
          <div class="form-delivery-conditions__selection-btn-block">
            <label class="form-delivery-conditions__label">Дни недели</label>
            <selection-buttons-component
              :value="getDocumentHead.getDeliveryOrderDays"
              :data_list="orderDayList"
              :disabled="getDocumentHead.getDeliveryActive || getDocumentHead.fixed"
              @input="changeOrderDays"
            />
          </div>
          <div class="form-delivery-conditions__selection-btn-block">
            <label class="form-delivery-conditions__label">Недели месяца</label>
            <selection-buttons-component
              :value="getDocumentHead.getDeliveryOrderWeeks"
              :data_list="orderWeekList"
              :disabled="getDocumentHead.getDeliveryActive || getDocumentHead.fixed"
              @input="changeOrderWeeks"
            />
          </div>

          <div class="block-separator"></div>

          <h4 class="form-delivery-conditions__title-block">График поставок</h4>
          <div class="form-delivery-conditions__selection-btn-block">
            <label class="form-delivery-conditions__label">Дни недели</label>
            <selection-buttons-component
              :value="getDocumentHead.getDeliveryDays"
              :data_list="deliveryDayList"
              :disabled="getDocumentHead.getDeliveryActive || getDocumentHead.fixed"
              @input="changeDeliveryDays"
            />
          </div>
          <div class="form-delivery-conditions__selection-btn-block">
            <label class="form-delivery-conditions__label">Недели месяца</label>
            <selection-buttons-component
              :value="getDocumentHead.getDeliveryWeeks"
              :data_list="deliveryWeekList"
              :disabled="getDocumentHead.getDeliveryActive || getDocumentHead.fixed"
              @input="changeDeliveryWeeks"
            />
          </div>

          <div class="block-separator"></div>

          <h4 class="form-delivery-conditions__title-block">Условия заказа</h4>
          <label class="form-delivery-conditions__label">Мин. сумма заказа, ₽ </label>
          <div class="form-delivery-conditions__input-order-conditions-block">
            <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getDeliveryMinSum ?? '-' }}</div>
            <e-input
              v-else
              :value="getDocumentHead.getDeliveryMinSum"
              class="form-delivery-conditions__input-min-sum"
              placeholder=""
              type="number"
              :disabled="getDocumentHead.getDeliveryActive"
              @change="changeMinSum"
            />
          </div>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex/dist/vuex.esm.browser'
  import SelectionButtonsComponent from '../SelectionButtonsComponent.vue'
  export default {
    name: 'DeliveryConditionsSidebar',

    components: {
      SelectionButtonsComponent
    },

    data() {
      return {
        isSwitchActive: false,
        orderDayList: [
          { value: 0, text: 'пн', isSelected: false },
          { value: 1, text: 'вт', isSelected: false },
          { value: 2, text: 'ср', isSelected: false },
          { value: 3, text: 'чт', isSelected: false },
          { value: 4, text: 'пт', isSelected: false },
          { value: 5, text: 'сб', isSelected: false },
          { value: 6, text: 'вс', isSelected: false }
        ],
        orderWeekList: [
          { value: 0, text: '1', isSelected: false },
          { value: 1, text: '2', isSelected: false },
          { value: 2, text: '3', isSelected: false },
          { value: 3, text: '4', isSelected: false }
        ],
        deliveryDayList: [
          { value: 0, text: 'пн', isSelected: false },
          { value: 1, text: 'вт', isSelected: false },
          { value: 2, text: 'ср', isSelected: false },
          { value: 3, text: 'чт', isSelected: false },
          { value: 4, text: 'пт', isSelected: false },
          { value: 5, text: 'сб', isSelected: false },
          { value: 6, text: 'вс', isSelected: false }
        ],
        deliveryWeekList: [
          { value: 0, text: '1', isSelected: false },
          { value: 1, text: '2', isSelected: false },
          { value: 2, text: '3', isSelected: false },
          { value: 3, text: '4', isSelected: false }
        ]
      }
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead'
      })
    },

    methods: {
      ...mapMutations({
        SET_DOCUMENR_PARAM: 'contracts/SET_DOCUMENR_PARAM'
      }),

      async createTreatyDelivery() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../../gql/CreateTreatyDelivery.gql'),
            debounce: 500,
            variables: {
              input: this.getDocumentHead.inputTreatyDelivery
            }
          })
          this.$emit('refetch')
        } catch (err) {
          console.log(err)
        }
      },

      cangeContactName(text) {
        this.SET_DOCUMENR_PARAM({ key: 'contact_name', value: text })
        this.createTreatyDelivery()
      },

      changePhone(text) {
        this.SET_DOCUMENR_PARAM({ key: 'contact_phone', value: text })
        this.createTreatyDelivery()
      },

      changeEmail(text) {
        this.SET_DOCUMENR_PARAM({ key: 'contact_email', value: text })
        this.createTreatyDelivery()
      },

      changeOrderDays(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_order_days', value: newVal })
        this.createTreatyDelivery()
      },

      changeOrderWeeks(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_order_weeks', value: newVal })
        this.createTreatyDelivery()
      },

      changeDeliveryDays(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_days', value: newVal })
        this.createTreatyDelivery()
      },

      changeDeliveryWeeks(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_weeks', value: newVal })
        this.createTreatyDelivery()
      },

      changeMinSum(newSum) {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_min_sum', value: +newSum })
        this.createTreatyDelivery()
      },

      cangeIsSwitchActive() {
        this.SET_DOCUMENR_PARAM({ key: 'delivery_active', value: !this.getDocumentHead.getDeliveryActive })
        this.createTreatyDelivery()
      }
    }
  }
</script>

<style scoped lang="scss">
  .block-separator {
    background: var(--gray-gray-100, #e2e2e2);
    height: 1px;
    width: 100%;
    margin-bottom: 16px;
  }
  .form-delivery-conditions {
    padding: 22px;

    &__title-block {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }

    &__input-block {
      margin-bottom: 16px;
      width: 100%;
    }
    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__flex-input-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    &__selection-btn-block {
      margin-bottom: 16px;
    }

    &__input-order-conditions-block {
      width: 80px;
    }

    &__block-delivery-title {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
    }

    &__delivery-title {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &__switch-block {
      display: flex;
      align-items: center;
    }

    &__label-switch {
      display: flex;
      align-items: center;
      gap: 7px;
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__delivery-container {
      border: 1px solid var(--gray-gray-100, #e2e2e2);
      padding: 22px;
    }
  }

  ::v-deep() {
    #delivery-conditions-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }
</style>
