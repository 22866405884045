<template>
  <div class="contract-head">
    <document-options-sidebar @refetch="refetchDocument" />
    <delivery-conditions-sidebar @refetch="refetchDocument" />
    <terms-of-payment-sidebar @refetch="refetchDocument" />
    <div class="contract-menu">
      <div class="contract-menu__left-block">
        <div
          v-b-toggle.document-options-sidebar
          class="contract-menu__left-block-btn"
        >
          Параметры документа
        </div>
        <div
          v-b-toggle.delivery-conditions-sidebar
          class="contract-menu__left-block-btn"
        >
          <div>Условия поставки</div>
          <img
            v-b-tooltip.hover.top
            :title="toolTipDelivery"
            class="search-block__img"
            src="/img/icons/contract/info.svg"
            alt=""
          />
        </div>
        <div
          v-b-toggle.terms-of-payment-sidebar
          class="contract-menu__left-block-btn"
        >
          <div>Условия оплаты</div>
          <img
            v-b-tooltip.hover.top
            :title="toolTipTermsPayment"
            class="search-block__img"
            src="/img/icons/contract/info.svg"
            alt="search"
          />
        </div>
      </div>

      <div class="contract-menu__right-block">
        <e-button
          v-if="!getDocumentHead.fixed"
          size="m"
          variant="outline-primary"
          @click="back"
        >
          Сохранить черновик
        </e-button>
        <e-button
          size="m"
          variant="primary"
          class="cursor ml-3"
          :disabled="loading"
          :loading="loading"
          @click="changeContractHeadStatus"
        >
          <template v-if="is_request_update">
            <b-spinner
              variant="light"
              small
            />
          </template>
          <template v-else>
            {{ getDocumentHead.fixed ? 'Редактировать' : 'Провести' }}
          </template>
        </e-button>

        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/more.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="removeDocument">Удалить</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex/dist/vuex.esm.browser'
  import DocumentOptionsSidebar from './sidebar/DocumentOptionsSidebar.vue'
  import DeliveryConditionsSidebar from './sidebar/DeliveryConditionsSidebar.vue'
  import TermsOfPaymentSidebar from './sidebar/TermsOfPaymentSidebar.vue'
  export default {
    name: 'ContractHeadMenu',

    components: {
      TermsOfPaymentSidebar,
      DocumentOptionsSidebar,
      DeliveryConditionsSidebar
    },

    data() {
      return {
        loading: false,
        is_request_update: false,
        toolTipDelivery:
          'Используйте условия поставки по контракту, если они отличаются от договора. Условия по контракту всегда приоритетней условий по поставщику',
        toolTipTermsPayment:
          'Используйте условия оплаты по контракту, если они отличаются от договора. Условия по контракту всегда приоритетней условий по оплате'
      }
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead'
      })
    },

    methods: {
      ...mapMutations({
        SET_LIST_WARNING_SPEC: 'contracts/SET_LIST_WARNING_SPEC'
      }),

      refetchDocument() {
        this.$emit('refetch')
      },

      async removeDocument() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/DeleteContractHead.gql'),
            variables: {
              ids: [this.$route.params?.id]
            }
          })
          this.$noty.show(`Удалено`)
          this.back()
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      back() {
        this.$emit('refetch')
        this.$router.back()
      },

      emailValidate(email) {
        if (!email) return false
        return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email) || !email
      },

      async changeContractHeadStatus() {
        const emailValid = this.emailValidate(this.getDocumentHead.getContactEmail)

        if (emailValid) {
          this.$noty.error('Не корректный email')
          return
        }
        try {
          this.loading = true
          await this.$apollo.mutate({
            mutation: require('../../gql/ChangeContractHeadStatus.gql'),
            debounce: 500,
            variables: {
              id: this.getDocumentHead.id
            }
          })
          this.$emit('refetch')
        } catch (err) {
          console.log(err)
          const listWarningSpec = []
          err?.graphQLErrors?.forEach((el) => {
            if (el?.message === 'CANT_FIX_CONTRACT') {
              listWarningSpec.push(...el?.extensions?.data?.ids)
              this.SET_LIST_WARNING_SPEC(listWarningSpec)
              this.$bvModal.show('posting-document-warning-modal')
            }
          })

          err?.graphQLErrors?.forEach((el) => {
            if (el?.message === 'NO_SUPPLIER_OR_ENTITY') {
              this.$noty.error('Заполните поля "Поставщик" и "Организация"')
            }
          })
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .contract-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left-block {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__left-block-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      border-radius: 2px;
      background: var(--gray-gray-100, #e2e2e2);
      padding: 5px 12px;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__right-block {
      display: flex;
      align-items: center;
    }
  }
</style>
