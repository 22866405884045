var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "posting-document-warning-modal",
      "centered": "",
      "size": "sm'",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-title-block d-flex justify-content-between w-100"
        }, [_c('div', {
          staticClass: "header-title-block__container"
        }, [_c('img', {
          staticClass: "search-block__img",
          attrs: {
            "src": "/img/icons/contract/warning.svg",
            "alt": "search"
          }
        }), _c('div', {
          staticClass: "header-title-block__title"
        }, [_vm._v("Проведение документа невозможно")])]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": _vm.hideModal
          }
        })])], 1)])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "modal-text-description"
  }, [_vm._v(" Выбранные товары присутствуют в других контрактах, удалите их из этого документа или разопроведите другие контракты. ")])], [_c('div', {
    staticClass: "modal-body"
  }, [_c('resizable-table', {
    ref: "table_posting_document_warning",
    staticClass: "table-posting-document-warning",
    attrs: {
      "table_name": "table-posting-document-warning",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "empty_text": "Пусто",
      "resizable": true,
      "busy": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item === null || item === void 0 ? void 0 : item.id);
            }
          }
        })];
      }
    }, {
      key: "body_name",
      fn: function (_ref2) {
        var _vm$getDocumentHead, _vm$getDocumentHead$s;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('div', {
          staticClass: "name-description"
        }, [_c('div', {
          staticClass: "name-description-text"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.articul))]), _c('div', {
          staticClass: "name-description-text"
        }, [_vm._v(_vm._s((_vm$getDocumentHead = _vm.getDocumentHead) === null || _vm$getDocumentHead === void 0 ? void 0 : (_vm$getDocumentHead$s = _vm$getDocumentHead.supplier) === null || _vm$getDocumentHead$s === void 0 ? void 0 : _vm$getDocumentHead$s.name))])])];
      }
    }])
  })], 1)], [_c('div', {
    staticClass: "modal-content__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.removeItems
    }
  }, [_vm._v(" Удалить выбранное ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.saveAsDraft
    }
  }, [_vm._v(" Сохранить как черновик ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Отмена ")])], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }