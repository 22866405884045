<template>
  <div class="table-block">
    <change-new-price-modal
      :head="getDocumentHead?.id"
      :all_selected="select_all"
      :selected="selected"
      @mass_update_contract_price="massUpdateContractSpec"
    />
    <change-new-price-date-modal
      :head="getDocumentHead?.id"
      :all_selected="select_all"
      :selected="selected"
      @mass_update_contract_date="massUpdateContractSpec"
    />
    <price-history-modal :item_id="itemId" />
    <mass-add-product @add_products="createSpec" />
    <posting-document-warning-modal
      @remove_warning-spec="removeWarningSpec"
      @save_as_draft="saveAsDraft"
    />

    <product-movement-modal :product="getProduct" />

    <div class="table-block__content">
      <div class="search-block">
        <div class="pos-r">
          <img
            class="search-block__img"
            src="/img/icons/contract/search.svg"
            alt="search"
          />

          <b-form-input
            :value="search"
            type="text"
            class="search-block__input"
            placeholder="Поиск"
            @change="handlerSearch"
          />
          <b-button
            v-if="search?.length > 0"
            class="search-block__btn-clear-search-str"
            @click.prevent="clear"
          />
        </div>

        <b-dropdown
          class="ml-3"
          no-caret
          variant="none"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="table">
        <resizable-table
          ref="table_contract"
          class="table-contract"
          table_name="table_contract"
          :show_product_search="true"
          :inside_card="false"
          :items="getSpecList"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="isBusy"
          @scroll-up="scrollUp"
          @scroll-down="scrollDown"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="changeSelectAll"
            />
          </template>

          <template #body_id="{ item }">
            <b-form-checkbox
              :checked="selected?.some((el) => el === item?.id)"
              position="center"
              @change="(val) => addSelected(val, item?.id)"
            />
          </template>

          <template #body_order="{ item }">
            {{ item?.order }}
          </template>

          <template #body_name="{ item }">
            <product-spec-info :product="item?.product" />
          </template>

          <template #body_future_date="{ item }">
            <date-picker
              id="work_period"
              :ref="item.id + 'future_date'"
              :clearable="false"
              class="date-input"
              :disabled="getDocumentHead?.fixed"
              placeholder="Выберите дату"
              :value="item?.getFutureBegin"
              format="dd.MM.yyyy"
              transfer
              @on-change="(value) => setDate(value, item)"
            />
          </template>

          <template #body_future_prices="{ item }">
            <table-row-input
              :ref="item.id + 'future_prices'"
              class="future-prices"
              :value="item?.getFuturePrice"
              :fixed="getDocumentHead?.fixed"
              :input_id="item.id + 'future_prices'"
              symbol="₽"
              @handle-input="(val) => setPrice(val, item)"
              @input="(val) => setPrice(val, item)"
            />
          </template>

          <template #body_begin="{ item }">
            <div>{{ convertDate(item?.begin) }}</div>
          </template>

          <template #body_price="{ item }">
            <div>{{ item?.price }}</div>
          </template>

          <template #body_reason="{ item }">
            <div class="reason-containet">
              <div
                class="reason-blok"
                :class="{
                  'reason-show': isReasonShow
                }"
              >
                <div
                  v-for="(el, index) of parsTextForLink(item?.reason?.text, item?.reason?.params)"
                  :key="index"
                  :class="{
                    'reason-link': el.link
                  }"
                  @click="el.link ? goLink(el?.params) : null"
                >
                  {{ el.text }}
                </div>
              </div>
              <img
                v-if="item?.reason?.text"
                src="/img/icons/contract/arrow-up.svg"
                class="ml-2 reason-img"
                :class="{
                  'reason-img-active': isReasonShow
                }"
                alt="arrow-menu"
                @click="showReasonText"
              />
            </div>
          </template>

          <template #productSearch>
            <div class="product-search-block">
              <product-search
                v-if="!getDocumentHead.fixed && scrollExist"
                style="width: 250px"
                class="ml-2 product-search"
                :document_head="getDocumentHead.id"
                :document="getDocumentHead"
                :show_add_button="true"
                document_type="contract"
                @select_product="select_product"
              />
            </div>
          </template>
        </resizable-table>

        <custom-navbar
          class="table-navbar"
          :items="selected"
          item_name="Позиция"
          @clear="clearSelected"
        >
          <template #buttons>
            <b-button
              v-if="!getDocumentHead?.fixed"
              variant="dark"
              @click="deleteSpecItem"
            >
              Удалить
            </b-button>

            <b-dropdown
              v-if="!getDocumentHead?.fixed"
              dropup
              no-caret
              class="navbar-dropdown"
              variant="dark"
            >
              <template #button-content>Действия</template>
              <b-dropdown-item @click="openChangeNewPriceModal">
                <div>Изменить новую цену</div>
              </b-dropdown-item>
              <b-dropdown-item @click="openChangeNewPriceDateModal">
                <div>Изменить дату новой цены</div>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-if="selected.length === 1"
              variant="dark"
              @click="openPriceHistoryModal"
              >История
            </b-button>

            <b-button
              v-if="selected.length === 1"
              variant="dark"
              @click="showProductMovement"
            >
              Товародвижение
            </b-button>
          </template>
        </custom-navbar>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import { ContractSpecModel } from '../../models/contract-spec.model'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import PostingDocumentWarningModal from './modal/PostingDocumentWarningModal.vue'
  import { mapGetters, mapMutations } from 'vuex/dist/vuex.esm.browser'
  import formatDate from '@/utils/formatDate'
  import ProductSearch from '../ProductSearch.vue'
  import ResizableTable from '@/components/ResizableTable.vue'
  import ChangeNewPriceModal from './modal/ChangeNewPriceModal.vue'
  import PriceHistoryModal from './modal/PriceHistoryModal.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  import ChangeNewPriceDateModal from './modal/ChangeNewPriceDateModal.vue'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import MassAddProduct from '@/views/operational-processes/components/incoming/modal/MassAddProduct'
  import { ContractSpecListModel } from '@/views/operational-processes/models/contract-spec-list.model'
  export default {
    name: 'ContractTable',

    components: {
      ProductSpecInfo,
      ProductMovementModal,
      ResizableTable,
      TableRowInput,
      CustomNavbar,
      PriceHistoryModal,
      ChangeNewPriceModal,
      ChangeNewPriceDateModal,
      MassAddProduct,
      ProductSearch,
      PostingDocumentWarningModal
    },

    apollo: {
      ContractSpecList: {
        query: require('../../gql/ContractSpecList.gql'),
        fetchPolicy: 'no-cache',
        debounce: 500,
        variables() {
          return {
            head: this.$route.params?.id,
            pagination: this.pagination
          }
        },
        result({ data }) {
          if (!data) return
          this.document = new ContractSpecListModel(data?.ContractSpecList)
          if (this.first) {
            this.SET_SPEC_LIST(this.document.list || [])
            this.prev_cursor = data?.ContractSpecList?.prev_cursor
            this.next_cursor = data?.ContractSpecList?.next_cursor
          } else {
            if (this.up) {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.SET_SPEC_LIST([...this.document.list, ...this.getSpecList])
              this.prev_cursor = data?.ContractSpecList?.prev_cursor
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
            } else {
              this.SET_SPEC_LIST([...this.getSpecList, ...this.document.list])
              this.next_cursor = data?.ContractSpecList?.next_cursor
            }
          }
          this.isBusy = false
          this.first = false
          this.loading = false
        },
        error(error) {
          this.isLoading = false
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        search: '',
        isReasonShow: false,
        select_all: false,
        selected: [],
        scrollExist: true,
        itemId: '',
        document: new ContractSpecListModel(),
        up: false,
        first: true,
        loading: false,
        isBusy: false,
        prev_cursor: '',
        next_cursor: '',
        pagination: {
          search: '',
          prev_cursor: '',
          next_cursor: '',
          skip: 0,
          take: 20
        },
        fields: [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            width: 50
          },
          {
            key: 'order',
            checked: true,
            label: '№',
            // sortable: true,
            width: 80
          },
          {
            key: 'name',
            checked: true,
            label: 'Наименование товара',
            width: 290
          },
          {
            key: 'future_date',
            checked: true,
            label: 'Дата новой цены',
            width: 150
          },
          {
            key: 'future_prices',
            checked: true,
            label: 'Цена новая, ₽',
            width: 150
          },
          {
            key: 'begin',
            checked: true,
            label: 'Дата текущей цены',
            width: 150
          },
          {
            key: 'price',
            checked: true,
            label: 'Цена текущая, ₽',
            width: 155
          },
          {
            key: 'reason',
            checked: true,
            label: 'Причина',
            width: 325
          }
        ]
      }
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead',
        getSpecList: 'contracts/getSpecList'
      }),
      getProduct() {
        return this.getSpecList?.find((el) => el.id === this.selected[0])?.product ?? []
      }
    },

    watch: {
      getSpecList(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.getSpecList.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.getSpecList.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    methods: {
      ...mapMutations({
        SET_SPEC_LIST: 'contracts/SET_SPEC_LIST',
        SET_SPEC_PARAM: 'contracts/SET_SPEC_PARAM'
      }),

      resetPagination() {
        this.first = true
        this.loading = true
        this.pagination.next_cursor = ''
        this.pagination.prev_cursor = ''
      },

      parsTextForLink(text, params) {
        if (!text) return []
        let index = 0
        let result = []
        let textArr = text?.split('') ?? []
        while (textArr.length) {
          const openParenthesis = textArr.indexOf('{')
          const closeParenthesis = textArr.indexOf('}')

          if (openParenthesis < 0) {
            const dataText = {
              text: textArr.slice(0, openParenthesis).join('').trim(),
              link: false,
              params: []
            }
            result.push(dataText)
            textArr = []
          } else {
            if (openParenthesis !== 0) {
              const dataText = {
                text: textArr.slice(0, openParenthesis).join('').trim(),
                link: false,
                params: []
              }
              result.push(dataText)
              textArr = textArr.slice(openParenthesis, textArr?.length)
            } else {
              const dataText = {
                text: textArr
                  .slice(openParenthesis + 1, closeParenthesis)
                  .join('')
                  .trim(),
                link: true,
                params: params?.[index]
              }
              result.push(dataText)
              textArr = textArr.slice(closeParenthesis + 1, textArr?.length)
              index++
            }
          }
        }
        return result
      },

      goLink(params) {
        this.$router.push({
          name: `operation-process.overhead.${params?.type}.document.edit`,
          params: { id: params?.value }
        })
      },

      showProductMovement() {
        this.$bvModal.show('product-movement')
      },

      showReasonText() {
        this.isReasonShow = !this.isReasonShow
      },

      handlerSearch(val) {
        this.resetPagination()
        this.pagination.search = val
        this.$apollo.queries.ContractSpecList.refetch()
      },

      massUpdateContractSpec() {
        this.clearSelected()
        this.resetPagination()
        this.$apollo.queries.ContractSpecList.refetch()
      },

      removeWarningSpec(data) {
        this.selected = data
        this.deleteSpecItem()
      },

      saveAsDraft() {
        this.$router.back()
      },

      setPrice(val, item) {
        const param = {
          id: item.id,
          key: 'future_price',
          value: val
        }
        this.SET_SPEC_PARAM(param)
        this.addContractFuturePrice(item)
      },

      setDate(val, item) {
        const param = {
          id: item.id,
          key: 'future_begin',
          value: this.toNormalDate(val)
        }
        this.SET_SPEC_PARAM(param)
        this.addContractFuturePrice(item)
      },

      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      async addContractFuturePrice(item) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/AddContractFuturePrice.gql'),
            debounce: 500,
            variables: {
              input: item.inputContractSpec
            }
          })
          const newSpec = new ContractSpecModel(data?.AddContractFuturePrice)
          const newSpecList = this.getSpecList.map((el) => {
            if (el.id === newSpec.id) {
              el = newSpec
            }
            return el
          })
          this.SET_SPEC_LIST(newSpecList)
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      async deleteSpecItem() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/DeleteContractSpec.gql'),
            debounce: 500,
            variables: {
              input: {
                document_head: this.getDocumentHead.id,
                ids: this.selected,
                isAllSelected: this.select_all
              }
            }
          })
          this.$noty.show(`Удалено`)
          // this.first = true
          this.resetPagination()
          this.SET_SPEC_LIST([])
          this.$apollo.queries.ContractSpecList.refetch()
          this.clearSelected()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      createSpec(spec) {
        this.createContractSpec(spec)
      },

      scrollUp() {
        if (!this.prev_cursor || this.$apollo.queries.ContractSpecList.loading) return
        this.up = true
        this.first = false
        this.loading = true
        this.pagination.prev_cursor = this.prev_cursor
        this.pagination.next_cursor = ''
        this.$apollo.queries.ContractSpecList.refetch()
      },

      scrollDown() {
        if (!this.next_cursor || this.$apollo.queries.ContractSpecList.loading) return
        this.pagination.next_cursor = this.next_cursor
        this.pagination.prev_cursor = ''
        this.up = false
        this.first = false
        this.loading = true
        this.$apollo.queries.ContractSpecList.refetch()
      },

      async createContractSpec(items) {
        const products = items.map((el) => {
          return { product: el.id }
        })

        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/CreateContractSpec.gql'),
            variables: {
              head: this.getDocumentHead?.id,
              input: products
            }
          })

          this.$noty.show(`Добавлено`)
          this.pagination.next_cursor = data?.CreateContractSpec?.next_cursor
          this.pagination.prev_cursor = ''
          this.loading = true
          this.first = true
          await this.$apollo.queries.ContractSpecList.refetch()

          setTimeout(() => {
            this.$nextTick(() => {
              // let ref = this.$refs[`${data.CreateContractSpec?.list?.[0]?.id}future_prices`]
              let ref = this.$refs[`${data.CreateContractSpec?.list?.[0]?.id}future_date`]
              // ref.showInput()
              // ref.handleFocus()
              ref.focus()

              this.$nextTick(() => {
                ref.$el.scrollIntoView({ block: 'center', behavior: 'smooth' })
                this.loading = true
                this.first = true
              })
            })
          }, 200)
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      async select_product(item) {
        if (item.specification) {
          // let ref = this.$refs[`${item.specification.id}future_prices`]
          let ref = this.$refs[`${item.specification.id}future_date`]

          if (!ref) {
            this.pagination.next_cursor = item.specification.cursor
            this.pagination.prev_cursor = ''
            await this.$apollo.queries.ContractSpecList.refetch()

            // let ref = this.$refs[`${item.specification.id}future_prices`]
            let ref = this.$refs[`${item.specification.id}future_date`]

            setTimeout(() => {
              this.$nextTick(() => {
                ref.focus()
              })
              this.$nextTick(() => {
                ref.$el.scrollIntoView({ block: 'center', behavior: 'smooth' })
              })
            }, 100)
          }

          setTimeout(() => {
            this.$nextTick(() => {
              ref.focus()
            })

            this.$nextTick(() => {
              ref.$el.scrollIntoView({ block: 'center', behavior: 'smooth' })
            })
          }, 100)

          return
        }

        this.createContractSpec([item])
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          this.getSpecList.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []
        this.getSpecList.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.getSpecList.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.getSpecList.length) this.select_all = false
        if (currentSeleted.length === this.getSpecList.length) this.select_all = true
      },

      clear() {
        this.search = ''
      },

      clearSelected() {
        this.select_all = false
        this.selected = []
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      },

      openChangeNewPriceModal() {
        this.$bvModal.show('change-new-price-modal')
      },

      openChangeNewPriceDateModal() {
        this.$bvModal.show('change-new-price-date-modal')
      },

      openPriceHistoryModal() {
        this.itemId = this.selected[0]
        this.$bvModal.show('price-history-modal')
      },

      showMassAddProduct() {
        this.$bvModal.show('mass_add_product')
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .b-search-result {
    height: 300px;
  }
  :deep .p-0 {
    display: flex;
    flex-direction: column;
  }
  .table-navbar {
    position: absolute;
    bottom: 10px;
    right: 20px;
    left: 20px;
  }

  .navbar-dropdown {
    &__item-description {
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .reason-containet {
    display: flex;
    justify-content: space-between;
  }
  .reason-blok {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .reason-show {
    text-wrap: wrap;
    flex-wrap: wrap;
  }

  .reason-link {
    color: var(--text-system-blue-400, #00a3ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    padding-bottom: 3px;
  }

  .reason-img {
    transform: rotate(180deg);
  }

  .reason-img-active {
    transform: rotate(0deg);
  }

  .table-block {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: scroll;

    :deep th {
      height: 60px;
      color: var(--text-secondary-gray-400, #888) !important;
    }
  }

  .b-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0px;
    top: 50%;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
  }

  .table-status-true {
    width: 91px;
    padding: 0px 12px;
    border-radius: 3px;
    background: #ebf9f5;
    color: #00cb91;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .table-status-false {
    // padding: 0px 12px;
    width: 114px;
    border-radius: 3px;
    background: var(--gray-gray-50, #efefef);
    color: var(--text-secondary-gray-400, #888);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }

  .product-search-block {
    height: 27px;
    width: 450px;
    display: flex;
    gap: 5px;
  }

  .date-input {
    border: none;
    cursor: pointer;
    border-bottom: 1px dotted #313131;

    &:focus {
      border: 1px solid #80d1ff;
    }
  }
</style>
