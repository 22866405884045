var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-block"
  }, _vm._l(_vm.data_list, function (item) {
    return _c('div', {
      key: item === null || item === void 0 ? void 0 : item.value,
      staticClass: "selection-button",
      class: {
        'selection-button__background-active': (item === null || item === void 0 ? void 0 : item.isSelected) && !_vm.disabled,
        'selection-button__background-no-active': !(item !== null && item !== void 0 && item.isSelected) && !_vm.disabled,
        'selection-button__background-active-disabled': (item === null || item === void 0 ? void 0 : item.isSelected) && _vm.disabled,
        'selection-button__background-no-active-disabled': !(item !== null && item !== void 0 && item.isSelected) && _vm.disabled
      },
      on: {
        "click": function ($event) {
          return _vm.clickButton(item === null || item === void 0 ? void 0 : item.text);
        }
      }
    }, [_c('div', {
      staticClass: "selection-button__content"
    }, [_c('div', {
      staticClass: "selection-button__title-block"
    }, [_c('div', {
      staticClass: "selection-button__title",
      class: {
        'selection-button__title-color-active': item === null || item === void 0 ? void 0 : item.isSelected,
        'selection-button__title-color-no-active-disabled': !(item !== null && item !== void 0 && item.isSelected) && _vm.disabled
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.text) + " ")])])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }