import { ContractSpecModel } from '@/views/operational-processes/models/contract-spec.model'

export class ContractSpecListModel {
  constructor(request = {}) {
    this.total = request?.total
    this.prev_cursor = request?.prev_cursor
    this.next_cursor = request?.next_cursor
    this.list = request?.list?.map((el) => new ContractSpecModel(el)) ?? []
  }
}
