<template>
  <div class="container-block">
    <div
      v-for="item of data_list"
      :key="item?.value"
      class="selection-button"
      :class="{
        'selection-button__background-active': item?.isSelected && !disabled,
        'selection-button__background-no-active': !item?.isSelected && !disabled,
        'selection-button__background-active-disabled': item?.isSelected && disabled,
        'selection-button__background-no-active-disabled': !item?.isSelected && disabled
      }"
      @click="clickButton(item?.text)"
    >
      <div class="selection-button__content">
        <div class="selection-button__title-block">
          <div
            class="selection-button__title"
            :class="{
              'selection-button__title-color-active': item?.isSelected,
              'selection-button__title-color-no-active-disabled': !item?.isSelected && disabled
            }"
          >
            {{ item?.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectionButtonsComponent',

    props: {
      data_list: {
        type: Array,
        default: () => []
      },
      value: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isActive: false,
        selectedArr: []
      }
    },

    watch: {
      value(newVal) {
        this.selectedArr = []
        newVal.forEach((val) => {
          this.data_list.forEach((el) => {
            if (el.value === val) {
              el.isSelected = true
              this.selectedArr.push(el)
            }
          })
        })
      }
    },

    mounted() {
      this.value.forEach((val) => {
        this.data_list.forEach((el) => {
          if (el.value === val) {
            el.isSelected = true
            this.selectedArr.push(el)
          }
        })
      })
    },

    methods: {
      clickButton(text) {
        if (this.disabled) return

        this.data_list.forEach((el) => {
          if (el.text === text) {
            el.isSelected = !el.isSelected
            if (!this.selectedArr.some((el) => el.text === text)) {
              this.selectedArr.push(el)
            }
          }
        })

        this.selectedArr.forEach((el, idx) => {
          if (!el.isSelected) {
            this.selectedArr.splice(idx, 1)
          }
        })

        const resultArr = this.selectedArr.map((el) => el.value)
        this.$emit('input', resultArr)
      }
    }
  }
</script>

<style scoped lang="scss">
  .container-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .selection-button {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;

    &__title {
      color: var(--text-primary-gray-700, #313131);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    &__title-color-active {
      color: var(--text-system-blue-400, #00a3ff);
    }

    &__title-color-no-active-disabled {
      color: var(--text-secondary-gray-400, #888);
    }

    &__background-active {
      background-color: var(--blue-100, #ddf3ff);
      border: 1px solid var(--blue-100, #ddf3ff);
      color: var(--text-system-blue-400, #00a3ff);
    }

    &__background-no-active {
      background-color: var(--gray-white, #fff);
      border: 1px solid var(--gray-gray-150, #e1e1e1);
    }

    &__background-active-disabled {
      background-color: #ddf3ff;
      border: 1px solid var(--blue-100, #ddf3ff);
      color: var(--text-system-blue-400, #00a3ff);
    }

    &__background-no-active-disabled {
      border: 1px solid var(--gray-gray-150, #e1e1e1);
      background: var(--secondary-b-50, #f2f3f6);
    }
  }
</style>
