import { render, staticRenderFns } from "./DocumentOptionsSidebar.vue?vue&type=template&id=4f11d7a8&scoped=true&"
import script from "./DocumentOptionsSidebar.vue?vue&type=script&lang=js&"
export * from "./DocumentOptionsSidebar.vue?vue&type=script&lang=js&"
import style0 from "./DocumentOptionsSidebar.vue?vue&type=style&index=0&id=4f11d7a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f11d7a8",
  null
  
)

export default component.exports