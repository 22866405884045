var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-head"
  }, [_c('document-options-sidebar', {
    on: {
      "refetch": _vm.refetchDocument
    }
  }), _c('delivery-conditions-sidebar', {
    on: {
      "refetch": _vm.refetchDocument
    }
  }), _c('terms-of-payment-sidebar', {
    on: {
      "refetch": _vm.refetchDocument
    }
  }), _c('div', {
    staticClass: "contract-menu"
  }, [_c('div', {
    staticClass: "contract-menu__left-block"
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.document-options-sidebar",
      modifiers: {
        "document-options-sidebar": true
      }
    }],
    staticClass: "contract-menu__left-block-btn"
  }, [_vm._v(" Параметры документа ")]), _c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.delivery-conditions-sidebar",
      modifiers: {
        "delivery-conditions-sidebar": true
      }
    }],
    staticClass: "contract-menu__left-block-btn"
  }, [_c('div', [_vm._v("Условия поставки")]), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "search-block__img",
    attrs: {
      "title": _vm.toolTipDelivery,
      "src": "/img/icons/contract/info.svg",
      "alt": ""
    }
  })]), _c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.terms-of-payment-sidebar",
      modifiers: {
        "terms-of-payment-sidebar": true
      }
    }],
    staticClass: "contract-menu__left-block-btn"
  }, [_c('div', [_vm._v("Условия оплаты")]), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "search-block__img",
    attrs: {
      "title": _vm.toolTipTermsPayment,
      "src": "/img/icons/contract/info.svg",
      "alt": "search"
    }
  })])]), _c('div', {
    staticClass: "contract-menu__right-block"
  }, [!_vm.getDocumentHead.fixed ? _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить черновик ")]) : _vm._e(), _c('e-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "size": "m",
      "variant": "primary",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.changeContractHeadStatus
    }
  }, [_vm.is_request_update ? [_c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": ""
    }
  })] : [_vm._v(" " + _vm._s(_vm.getDocumentHead.fixed ? 'Редактировать' : 'Провести') + " ")]], 2), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.removeDocument
    }
  }, [_vm._v("Удалить")])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }