<template>
  <b-modal
    id="price-history-modal"
    centered
    size="xl"
    hide-footer
    @shown="getHistory"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-title-block d-flex justify-content-between w-100">
          <div class="header-title-block__container">
            <h5 class="header-title-block__title">История изменения цены</h5>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>
      </div>
    </template>

    <template>
      <div class="search-block">
        <b-form
          class="pos-r"
          @submit.prevent="handlerSearch"
        >
          <img
            class="search-block__img"
            src="/img/icons/contract/search.svg"
            alt="search"
          />

          <b-form-input
            v-model="pagination.search"
            type="text"
            class="search-block__input"
            placeholder="Поиск"
          />
          <b-button
            v-if="search?.length > 0"
            class="search-block__btn-clear-search-str"
            @click.prevent="clear"
          />
        </b-form>

        <b-dropdown
          class="ml-3"
          no-caret
          variant="none"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <template>
      <div class="modal-body">
        <resizable-table
          ref="table_history_modal"
          class="table-history-price-modal"
          table_name="table-history-price-modal"
          sort_table_modal_id="table-history-price-modal"
          :items="items"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="isLoading"
        >
          <template #head_id>
            <div class="head-lable"></div>
          </template>

          <template #body_date="{ item }">
            <div>
              {{ convertDate(getBegin(item)?.newValue) }}
            </div>
          </template>

          <template #body_price="{ item }">
            <div>
              {{ getPrice(item)?.newValue }}
            </div>
          </template>

          <template #body_user="{ item }">
            <div class="user-block">
              <div>{{ item?.user?.last_name }}</div>
              <div>{{ item?.user?.first_name }}</div>
              <div>{{ item?.user?.second_name }}</div>
            </div>
          </template>

          <template #body_createdDate="{ item }">
            <div>
              {{ convertDate(item?.createdDate) }}
            </div>
          </template>

          <template #body_reason="{ item }">
            <div class="reason-containet">
              <div
                class="reason-blok"
                :class="{
                  'reason-show': isReasonShow
                }"
              >
                <div
                  v-for="(el, index) of parsTextForLink(item?.reason?.text, item?.reason?.params)"
                  :key="index"
                  :class="{
                    'reason-link': el.link
                  }"
                  @click="el.link ? goLink(el?.params) : null"
                >
                  {{ el.text }}
                </div>
              </div>
              <img
                v-if="item.reason.text"
                src="/img/icons/contract/arrow-up.svg"
                class="ml-2 reason-img"
                :class="{
                  'reason-img-active': isReasonShow
                }"
                alt="arrow-menu"
                @click="showReasonText"
              />
            </div>
          </template>
        </resizable-table>

        <pagination
          :total="total"
          table_name="contract_histity"
          @change_pagination="handlerPagination"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import ResizableTable from '@/components/ResizableTable.vue'
  import Pagination from '@/views/shared/components/table/Pagination'

  export default {
    name: 'PriceHistoryModal',

    components: {
      Pagination,
      ResizableTable
    },

    props: {
      item_id: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        items: [],
        isReasonShow: false,
        isLoading: true,
        search: '',
        total: null,
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        },
        fields: [
          // {
          //   key: 'id',
          //   checked: true,
          //   label: '',
          //   isRowHeader: true,
          //   width: 50
          // },
          {
            key: 'date',
            checked: true,
            label: 'Дата начала',
            width: 100
          },
          {
            key: 'price',
            checked: true,
            label: 'Цена, ₽',
            width: 80
          },
          {
            key: 'user',
            checked: true,
            label: 'Менеджер',
            width: 230
          },
          {
            key: 'createdDate',
            checked: true,
            label: 'Дата изменения',
            width: 120
          },
          {
            key: 'reason',
            checked: true,
            label: 'Причина',
            width: 250
          }
        ]
      }
    },

    methods: {
      showSortModal() {
        this.$bvModal.show('table-history-price-modal')
      },
      hideModal() {
        this.$bvModal.hide('price-history-modal')
      },

      parsTextForLink(text, params) {
        let index = 0
        let result = []
        let textArr = text?.split('') ?? []
        while (textArr.length) {
          const openParenthesis = textArr.indexOf('{')
          const closeParenthesis = textArr.indexOf('}')

          if (openParenthesis < 0) {
            const dataText = {
              text: textArr.slice(0, openParenthesis).join('').trim(),
              link: false,
              params: []
            }
            result.push(dataText)
            textArr = []
          } else {
            if (openParenthesis !== 0) {
              const dataText = {
                text: textArr.slice(0, openParenthesis).join('').trim(),
                link: false,
                params: []
              }
              result.push(dataText)
              textArr = textArr.slice(openParenthesis, textArr?.length)
            } else {
              const dataText = {
                text: textArr
                  .slice(openParenthesis + 1, closeParenthesis)
                  .join('')
                  .trim(),
                link: true,
                params: params?.[index]
              }
              result.push(dataText)
              textArr = textArr.slice(closeParenthesis + 1, textArr?.length)
              index++
            }
          }
        }
        return result
      },

      goLink(params) {
        console.log(params)
      },

      showReasonText() {
        this.isReasonShow = !this.isReasonShow
      },

      handlerPagination(option) {
        this.pagination.skip = (option.current_page - 1) * option.take
        this.pagination.take = option.take
        this.getHistory()
      },

      handlerSearch() {
        this.pagination.skip = 0
        this.pagination.take = 20
        this.getHistory()
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      getBegin(item) {
        return item?.data?.find((el) => el.key === 'begin') ?? {}
      },

      getPrice(item) {
        return item?.data?.find((el) => el.key === 'price') ?? {}
      },

      async getHistory() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../../gql/ContractHistory.gql'),
            debounce: 500,
            variables: {
              input: {
                object: 'contract_spec',
                objectId: this.item_id,
                pagination: {
                  skip: this.pagination.skip,
                  take: this.pagination.take,
                  search: this.pagination.search
                }
              }
            }
          })
          this.items = data?.History?.list ?? []
          this.total = data?.History?.total ?? null
          this.isLoading = false
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
          this.isLoading = false
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .header-container {
    width: 100%;
    .header-title-block__container {
      display: flex;
      align-items: center;
    }
    .header-title-block {
      // margin-bottom: 16px;
      &__title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  .reason-containet {
    display: flex;
    justify-content: space-between;
  }
  .reason-blok {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .reason-show {
    text-wrap: wrap;
    flex-wrap: wrap;
  }

  .reason-link {
    color: var(--text-system-blue-400, #00a3ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    padding-bottom: 3px;
  }

  .reason-img {
    transform: rotate(180deg);
  }

  .reason-img-active {
    transform: rotate(0deg);
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px !important;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 22px;
  }

  ::v-deep() {
    #resizeable-table {
      .foot {
        background: #f8f8f9;
        .text-nowrap {
          padding: 8px !important;
        }
      }
      th {
        height: 54px !important;
      }
    }
  }

  ::v-deep() {
    tfoot {
      position: sticky;
      bottom: 0;

      .foot {
        padding: 0 !important;
      }

      td {
        border-right: none;
        padding: 0 !important;
      }
    }

    th {
      height: 54px !important;
    }
  }

  .user-block {
    display: flex;
    gap: 5px;
  }

  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
</style>
