import { render, staticRenderFns } from "./PostingDocumentWarningModal.vue?vue&type=template&id=e71d2dca&scoped=true&"
import script from "./PostingDocumentWarningModal.vue?vue&type=script&lang=js&"
export * from "./PostingDocumentWarningModal.vue?vue&type=script&lang=js&"
import style0 from "./PostingDocumentWarningModal.vue?vue&type=style&index=0&id=e71d2dca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e71d2dca",
  null
  
)

export default component.exports