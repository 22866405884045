var render = function render(){
  var _vm$getDocumentHead$g, _vm$getDocumentHead;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "terms-of-payment-sidebar",
      "title": "Условия оплаты",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Условия оплаты "), _c('div', {
          staticClass: "header-left-block"
        }, [_c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])];
      }
    }])
  }, [[_c('div', {
    staticClass: "form-terms-of-payment"
  }, [_c('label', {
    staticClass: "form-terms-of-payment__label"
  }, [_vm._v("Условия отплаты")]), _c('div', {
    staticClass: "form-terms-of-payment__switch-block"
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.getDocumentHead.getPaymentActive,
      "name": "check-button",
      "disabled": _vm.getDocumentHead.fixed,
      "switch": ""
    },
    on: {
      "change": _vm.cangeIsSwitchActive
    }
  }), _c('div', {
    staticClass: "form-terms-of-payment__label-switch"
  }, [_c('div', [_vm._v("Из договора")]), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.right",
      modifiers: {
        "hover": true,
        "right": true
      }
    }],
    attrs: {
      "title": "Значение будет взято из договора",
      "src": "/img/icons/contract/info.svg",
      "alt": "search"
    }
  })])], 1), _c('div', {
    staticClass: "form-terms-of-payment__radio-block"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.getDocumentHead.getPaymentTerm === 'AFTER_FIX',
      "disabled": _vm.getDocumentHead.getPaymentActive || _vm.getDocumentHead.fixed
    },
    on: {
      "click": function ($event) {
        return _vm.setTermsPayment('AFTER_FIX');
      }
    }
  }), _c('div', [_vm._v("От даты поставки")])], 1), _c('div', {
    staticClass: "form-terms-of-payment__radio-block"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.getDocumentHead.getPaymentTerm === 'AFTER_SUPPLIER_DATE',
      "disabled": _vm.getDocumentHead.getPaymentActive || _vm.getDocumentHead.fixed
    },
    on: {
      "click": function ($event) {
        return _vm.setTermsPayment('AFTER_SUPPLIER_DATE');
      }
    }
  }), _c('div', [_vm._v("От даты документа поставщика")])], 1), _c('div', {
    staticClass: "form-terms-of-payment__radio-block"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.getDocumentHead.getPaymentTerm === 'AFTER_SELL',
      "disabled": _vm.getDocumentHead.getPaymentActive || _vm.getDocumentHead.fixed
    },
    on: {
      "click": function ($event) {
        return _vm.setTermsPayment('AFTER_SELL');
      }
    }
  }), _c('div', [_vm._v("От реализации")])], 1), _c('div', {
    staticClass: "form-terms-of-payment__input-postponement-days-block"
  }, [_c('label', {
    staticClass: "form-terms-of-payment__label"
  }, [_vm._v("Отсрочка, Дней")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead$g = (_vm$getDocumentHead = _vm.getDocumentHead) === null || _vm$getDocumentHead === void 0 ? void 0 : _vm$getDocumentHead.getPaymentDueDate) !== null && _vm$getDocumentHead$g !== void 0 ? _vm$getDocumentHead$g : '-'))]) : _c('e-input', {
    staticClass: "form-terms-of-payment__input",
    attrs: {
      "value": _vm.getDocumentHead.getPaymentDueDate,
      "placeholder": "",
      "type": "number",
      "disabled": _vm.getDocumentHead.getPaymentActive
    },
    on: {
      "change": _vm.changePostponementDays
    }
  })], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }