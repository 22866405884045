<template>
  <div class="contract">
    <contract-head-menu @refetch="refetchDocument" />
    <div class="table-components">
      <contract-table />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import ContractTable from '../../components/contracts/ContractTable.vue'
  import ContractHeadMenu from '../../components/contracts/ContractHeadMenu.vue'
  export default {
    name: 'Contract',

    components: {
      ContractTable,
      ContractHeadMenu
    },

    apollo: {
      ContractHead: {
        query: require('../../gql/ContractHead.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params?.id
          }
        },
        result({ data }) {
          this.SET_DOCUMENT_HEAD(data?.ContractHead)
          this.setBreadcrumbs({ ...data?.ContractHead, is_go_back: true })
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {}
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead'
      })
    },

    async mounted() {
      await this.setBreadcrumbs({ is_go_back: true })
      if (this.$route.params?.creation) {
        this.$root.$emit('bv::toggle::collapse', 'document-options-sidebar')
      }
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      ...mapMutations({
        SET_DOCUMENT_HEAD: 'contracts/SET_DOCUMENT_HEAD'
      }),

      refetchDocument() {
        this.$apollo.queries.ContractHead.refetch()
      }
    }
  }
</script>

<style scoped lang="scss">
  .contract {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .table-components {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
</style>
