var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "change-new-price-modal",
      "centered": "",
      "hide-footer": "",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-container__block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Массовое изменение новой цены")])])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-content__input-block"
  }, [_c('label', {
    staticClass: "modal-content__label"
  }, [_vm._v("Новая цена, ₽ ")]), _c('e-input', {
    staticClass: "modal-content__input",
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.newPrice,
      callback: function ($$v) {
        _vm.newPrice = $$v;
      },
      expression: "newPrice"
    }
  })], 1), _c('div', {
    staticClass: "modal-content__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveNevPrice
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Отмена ")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }