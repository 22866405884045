<template>
  <b-modal
    id="posting-document-warning-modal"
    centered
    size="sm'"
    hide-footer
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-title-block d-flex justify-content-between w-100">
          <div class="header-title-block__container">
            <img
              class="search-block__img"
              src="/img/icons/contract/warning.svg"
              alt="search"
            />
            <div class="header-title-block__title">Проведение документа невозможно</div>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hideModal"
            />
          </b-button>
        </div>
      </div>
    </template>

    <template>
      <div class="modal-text-description">
        Выбранные товары присутствуют в других контрактах, удалите их из этого документа или разопроведите другие
        контракты.
      </div>
    </template>

    <template>
      <div class="modal-body">
        <resizable-table
          ref="table_posting_document_warning"
          class="table-posting-document-warning"
          table_name="table-posting-document-warning"
          :items="items"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="isLoading"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="changeSelectAll"
            />
          </template>

          <template #body_id="{ item }">
            <b-form-checkbox
              :checked="selected?.some((el) => el === item?.id)"
              position="center"
              @change="(val) => addSelected(val, item?.id)"
            />
          </template>

          <template #body_name="{ item }">
            <div class="name">{{ item?.name }}</div>
            <div class="name-description">
              <div class="name-description-text">{{ item?.articul }}</div>
              <div class="name-description-text">{{ getDocumentHead?.supplier?.name }}</div>
            </div>
          </template>
        </resizable-table>
      </div>
    </template>

    <template>
      <div class="modal-content__buttons-block">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="removeItems"
          >
            Удалить выбранное
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="saveAsDraft"
          >
            Сохранить как черновик
          </b-button>

          <b-button
            variant="light"
            class="cursor ml-3"
            @click="hideModal"
            >Отмена
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ResizableTable from '@/components/ResizableTable.vue'

  export default {
    name: 'PostingDocumentWarningModal',

    components: {
      ResizableTable
    },

    data() {
      return {
        isLoading: false,
        select_all: false,
        selected: [],
        fields: [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            width: 50
          },
          {
            key: 'name',
            checked: true,
            label: 'Товар',
            width: 300
          }
        ]
      }
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead',
        items: 'contracts/getListWarningSpec'
      })
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.items.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.items.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('posting-document-warning-modal')
      },

      saveAsDraft() {
        this.$emit('save_as_draft')
      },

      removeItems() {
        this.$emit('remove_warning-spec', this.selected)
        this.selected = []
        this.select_all = false
        this.hideModal()
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          this.items.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.select_all = false
        if (currentSeleted.length === this.items.length) this.select_all = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .header-container {
    width: 100%;

    .header-title-block__container {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    .header-title-block {
      // margin-bottom: 16px;
      &__title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  .modal-content {
    width: 100%;

    &__buttons-block {
      margin-top: 18px;
      margin-bottom: 22px;
    }
  }

  .modal-text-description {
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 22px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px !important;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 22px;
  }

  ::v-deep() {
    #resizeable-table {
      .foot {
        background: #f8f8f9;

        .text-nowrap {
          padding: 8px !important;
        }
      }

      th {
        height: 54px !important;
      }
    }
  }

  ::v-deep() {
    tfoot {
      position: sticky;
      bottom: 0;

      .foot {
        padding: 0 !important;
      }

      td {
        border-right: none;
        padding: 0 !important;
      }
    }

    th {
      height: 54px !important;
    }
  }

  .name {
    text-align: left;
  }

  .name-description {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .name-description-text {
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
</style>
