var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract"
  }, [_c('contract-head-menu', {
    on: {
      "refetch": _vm.refetchDocument
    }
  }), _c('div', {
    staticClass: "table-components"
  }, [_c('contract-table')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }