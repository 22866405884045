<template>
  <b-sidebar
    id="document-options-sidebar"
    title="Параметры документа"
    right
    backdrop
    shadow
    @shown="shownSidebar"
  >
    <template #header="{ hide }">
      Параметры документа
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <form class="form-document-options">
        <label class="form-document-options__label">Дата документа</label>
        <div
          v-if="getDocumentHead.fixed"
          class="form-document-options__date-range"
        >
          {{ convertDate(getDocumentHead.getDate) }}
        </div>
        <div
          v-else
          class="form-document-options__date-range"
        >
          <date-picker
            id="work_period"
            :clearable="false"
            class="elm-calendar"
            placeholder="Выберите дату"
            :value="getDocumentHead.getDate"
            format="dd.MM.yyyy"
            transfer
            @on-change="setDate"
          />
        </div>

        <div class="form-document-options__input-block">
          <label class="form-document-options__label">Операция</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getOperation?.[0]?.name }}</div>
          <div
            v-else
            class="segments-head__select-item"
          >
            <e-select
              :value="getDocumentHead?.getOperation"
              class="e-select-el"
              :options="operationsList"
              :background_color="'#e0edff'"
              :color_text="'#00A3FF'"
              :color_close_svg="'#00A3FF'"
              placeholder="Выберите операцию"
              select_name="operations_select"
              @change="changeOperation"
            >
              <template #custom_name="{ item }">
                <div>
                  <div class="e-select-el__option-name">{{ item.name }}</div>
                </div>
              </template>
            </e-select>
          </div>
        </div>

        <div class="form-document-options__input-block">
          <label class="form-document-options__label">Поставщик</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getSupplier?.[0]?.name }}</div>
          <div
            v-else
            class="segments-head__select-item"
          >
            <e-select
              :value="getDocumentHead.getSupplier"
              class="e-select-el"
              :options="supplierList"
              :background_color="'#e0edff'"
              :color_text="'#00A3FF'"
              :color_close_svg="'#00A3FF'"
              placeholder="Выберите поставщика"
              select_name="supplier_select"
              @change="changeSupplier"
            >
              <template #custom_name="{ item }">
                <div>
                  <div class="e-select-el__option-name">{{ item.name }}</div>
                  <div class="select-options-description">
                    <div class="select-options-description-text">ИНН:{{ item?.inn }}</div>
                    <div class="select-options-description-text">КПП:{{ item?.kpp }}</div>
                  </div>
                </div>
              </template>
            </e-select>
          </div>
        </div>

        <div class="form-document-options__input-block">
          <label class="form-document-options__label">Организация</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getEntity?.[0]?.name }}</div>
          <div
            v-else
            class="segments-head__select-item"
          >
            <e-select
              :value="getDocumentHead.getEntity"
              class="e-select-el"
              :options="entityList"
              :background_color="'#e0edff'"
              :color_text="'#00A3FF'"
              :color_close_svg="'#00A3FF'"
              placeholder="Выберите организацию"
              select_name="entity_select"
              @change="changeEntity"
            >
              <template #custom_name="{ item }">
                <div>
                  <div class="e-select-el__option-name">{{ item.name }}</div>
                  <div class="select-options-description">
                    <div class="select-options-description-text">ИНН:{{ item?.inn }}</div>
                    <div class="select-options-description-text">КПП:{{ item?.kpp }}</div>
                  </div>
                </div>
              </template>
            </e-select>
          </div>
        </div>

        <div class="form-document-options__input-block">
          <label class="form-document-options__label">Магазин</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getBranch?.[0]?.name }}</div>
          <div
            v-else
            class="segments-head__select-item"
          >
            <e-select
              :value="getDocumentHead.getBranch"
              class="e-select-el"
              :options="branchList"
              :background_color="'#e0edff'"
              :color_text="'#00A3FF'"
              :color_close_svg="'#00A3FF'"
              placeholder="Выберите магазин"
              select_name="branch_select"
              @change="changeBranch"
            >
              <template #custom_name="{ item }">
                <div>
                  <div class="e-select-el__option-name">{{ item.name }}</div>
                </div>
              </template>
            </e-select>
          </div>
        </div>

        <div class="form-document-options__input-block">
          <label class="form-document-options__label">Наименование</label>
          <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getName }}</div>
          <e-input
            v-else
            :value="getDocumentHead?.getName"
            class="form-document-options__input"
            placeholder="Введите наименование контракта"
            type="text"
            @change="changeNameContract"
          />
        </div>

        <label class="form-document-options__label">Период действия</label>
        <div v-if="getDocumentHead.fixed">{{ getDocumentHead?.getPeriod }}</div>
        <div
          v-else
          class="form-document-options__date-range"
        >
          <date-picker
            v-model="filter_date_validity"
            :clearable="false"
            type="daterange"
            :open="datepicker_validity_open"
            :editable="false"
            format="dd.MM.yyyy"
            class="elm-calendar hidden-picker"
            placeholder="Рабочий период"
            @on-change="setPeriodValidity({ period: 'custom' })"
            @on-clickoutside="clickedOutsideValidity"
          />
          <b-dropdown
            no-caret
            variant="none"
            :disabled="datepicker_validity_open"
          >
            <template #button-content>
              <div class="form-document-options__date-range-btn">
                <span style="color: black">{{ getDocumentHead.getPeriod }}</span>
                <img
                  src="/img/icons/calendar-dark.svg"
                  alt="calendar"
                  class="ml-2"
                />
              </div>
            </template>
            <b-dropdown-item
              class="sm-dropdown"
              @click="setPeriodValidity({ period: null })"
            >
              Бессрочный
            </b-dropdown-item>

            <b-dropdown-item
              class="sm-dropdown"
              @click.stop="datepicker_validity_open = true"
            >
              Указать период
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </form>
    </template>
  </b-sidebar>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters, mapMutations } from 'vuex/dist/vuex.esm.browser'
  export default {
    name: 'DocumentOptionsSidebar',

    apollo: {
      Entities: {
        query: require('@/views/operational-processes/gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          const filterEntity = data?.Entities?.filter((el) => el?.tags?.includes('ENTITY'))
          const filterSupplier = data?.Entities?.filter((el) => el?.tags?.includes('SUPPLIER'))
          this.entityList = filterEntity ?? []
          this.supplierList = filterSupplier ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        operationsList: [{ id: 1, name: 'Контракт' }],
        branchList: [],
        supplierList: [],
        entityList: [],
        filter_date_validity: [],
        selected_type_validity: null,
        datepicker_validity_open: false
      }
    },

    computed: {
      ...mapGetters({
        getDocumentHead: 'contracts/getDocumentHead',
        getCurrentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      ...mapMutations({
        SET_DOCUMENR_PARAM: 'contracts/SET_DOCUMENR_PARAM'
      }),

      async updateContractHead() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../../gql/UpdateContractHead.gql'),
            debounce: 500,
            variables: {
              input: this.getDocumentHead.inputDocumentOptions
            }
          })
          this.$emit('refetch')
        } catch (err) {
          console.log(err)
        }
      },

      shownSidebar() {
        const list = [
          { id: 1, name: 'Все магазины', value: null },
          { id: this.getCurrentBranch.id, name: this.getCurrentBranch.name, value: this.getCurrentBranch.id }
        ]
        this.branchList = list
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      changeOperation(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'operation', value: newVal[0] })
        this.updateContractHead()
      },

      changeBranch(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'branch', value: newVal[0] })
        this.updateContractHead()
      },

      changeSupplier(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'name', value: `Контракт с ${newVal[0].name}` })
        this.SET_DOCUMENR_PARAM({ key: 'supplier', value: newVal[0] })
        this.updateContractHead()
      },

      changeEntity(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'entity', value: newVal[0] })
        this.updateContractHead()
      },

      changeNameContract(newVal) {
        this.SET_DOCUMENR_PARAM({ key: 'name', value: newVal })
        this.updateContractHead()
      },

      updateDatevalidity() {
        this.SET_DOCUMENR_PARAM({ key: 'period', value: this.filter_date_validity })
        this.updateContractHead()
      },

      setDate(val) {
        const param = { key: 'date', value: this.toNormalDate(val) }
        this.SET_DOCUMENR_PARAM(param)
        this.updateContractHead()
      },

      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      clickedOutsideValidity() {
        this.datepicker_validity_open = false
      },

      setPeriodValidity({ period }) {
        switch (period) {
          case 'custom': {
            const utc = new Date().getTimezoneOffset() / 60

            let dtBegin = new Date(this.filter_date_validity[0])
            let dtEnd = new Date(this.filter_date_validity[1])

            dtBegin.setHours(Math.abs(utc))
            dtEnd.setHours(23, 59, 59)
            this.filter_date_validity[0] = dtBegin
            this.filter_date_validity[1] = dtEnd
            this.datepicker_validity_open = false
            break
          }
          default: {
            this.selected_type_validity = []
            this.filter_date_validity[0] = null
            this.filter_date_validity[1] = null
            break
          }
        }
        this.selected_type_validity = period
        this.updateDatevalidity()
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .e-select-el__option-name {
    text-align: left;
  }
  .form-document-options {
    padding: 22px;

    &__date-range {
      margin-bottom: 20px;
    }

    &__date-range-btn {
      background: white;
      padding: 8px 10px;
      display: flex;
      flex-direction: row;
      height: 32px;
      border: 1px solid #bcbcbc;
    }

    &__input-block {
      margin-bottom: 22px;
      width: 100%;
    }

    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }
  }

  ::v-deep() {
    #document-options-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }

  .select-options-description {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .select-options-description-text {
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  ::v-deep() {
    #dropdown_select {
      width: 300px !important;
    }

    .inc-filters {
      flex-wrap: wrap;
      grid-gap: 0.5rem;
    }

    .inc-select-picker {
      width: 230px;

      .ivu-tag {
        max-width: 110px;
      }
    }

    .datepicker-range {
      width: 210px;
    }

    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .dropdown-menu {
      z-index: 2000 !important;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }

    .dropdown {
      width: 100%;
    }

    .dropdown > button > div {
      width: 100%;
      height: 33px !important;
      border: 1px solid var(--text-placeholder-gray-300, #bcbcbc) !important;
    }

    .dropdown > button > div > img {
      margin-left: auto !important;
    }
  }

  .not-collapsed img {
    transform: rotate(0deg) !important;
  }

  .collapsed img {
    transform: rotate(0deg) !important;
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .hidden-picker > div > div > input {
    display: none;
  }
</style>
