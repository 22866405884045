<template>
  <b-modal
    id="change-new-price-date-modal"
    centered
    hide-footer
    size="sm"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-container__block-title">
          <h5 class="title">Изменить дату новой цены</h5>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <div class="modal-content__input-block">
          <label class="modal-content__label">Новая дата</label>
          <div class="b-filter-date">
            <date-picker
              v-model="data_range"
              format="dd.MM.yyyy"
              :clearable="false"
              class="elm-calendar date"
              placeholder="Выберите период"
              transfer
            />
          </div>
        </div>
        <div class="modal-content__buttons-block">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="updateDateRange"
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="close"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'ChangeNewPriceDateModal',

    props: {
      head: {
        type: String,
        default: ''
      },
      all_selected: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        data_range: new Date()
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('change-new-price-date-modal')
      },
      async updateDateRange() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../../gql/MassUpdateContractSpec.gql'),
            debounce: 500,
            variables: {
              input: {
                head: this.head,
                ids: this.selected,
                all: this.all_selected,
                date: this.data_range
              }
            }
          })
          this.$emit('mass_update_contract_date')
          this.close()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 420px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    color: var(--text-primary-gray-700, #313131);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .modal-content {
    width: 100%;

    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__input-block {
      width: 205px;
    }

    &__buttons-block {
      margin-top: 18px;
      margin-bottom: 22px;
    }
  }
</style>
