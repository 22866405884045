var render = function render(){
  var _vm$getDocumentHead, _vm$getDocumentHead$g, _vm$getDocumentHead$g2, _vm$getDocumentHead2, _vm$getDocumentHead3, _vm$getDocumentHead3$, _vm$getDocumentHead3$2, _vm$getDocumentHead4, _vm$getDocumentHead4$, _vm$getDocumentHead4$2, _vm$getDocumentHead5, _vm$getDocumentHead5$, _vm$getDocumentHead5$2, _vm$getDocumentHead6, _vm$getDocumentHead7, _vm$getDocumentHead8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "document-options-sidebar",
      "title": "Параметры документа",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.shownSidebar
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Параметры документа "), _c('div', {
          staticClass: "header-left-block"
        }, [_c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])];
      }
    }])
  }, [[_c('form', {
    staticClass: "form-document-options"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Дата документа")]), _vm.getDocumentHead.fixed ? _c('div', {
    staticClass: "form-document-options__date-range"
  }, [_vm._v(" " + _vm._s(_vm.convertDate(_vm.getDocumentHead.getDate)) + " ")]) : _c('div', {
    staticClass: "form-document-options__date-range"
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "clearable": false,
      "placeholder": "Выберите дату",
      "value": _vm.getDocumentHead.getDate,
      "format": "dd.MM.yyyy",
      "transfer": ""
    },
    on: {
      "on-change": _vm.setDate
    }
  })], 1), _c('div', {
    staticClass: "form-document-options__input-block"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Операция")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead = _vm.getDocumentHead) === null || _vm$getDocumentHead === void 0 ? void 0 : (_vm$getDocumentHead$g = _vm$getDocumentHead.getOperation) === null || _vm$getDocumentHead$g === void 0 ? void 0 : (_vm$getDocumentHead$g2 = _vm$getDocumentHead$g[0]) === null || _vm$getDocumentHead$g2 === void 0 ? void 0 : _vm$getDocumentHead$g2.name))]) : _c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": (_vm$getDocumentHead2 = _vm.getDocumentHead) === null || _vm$getDocumentHead2 === void 0 ? void 0 : _vm$getDocumentHead2.getOperation,
      "options": _vm.operationsList,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Выберите операцию",
      "select_name": "operations_select"
    },
    on: {
      "change": _vm.changeOperation
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "form-document-options__input-block"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Поставщик")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead3 = _vm.getDocumentHead) === null || _vm$getDocumentHead3 === void 0 ? void 0 : (_vm$getDocumentHead3$ = _vm$getDocumentHead3.getSupplier) === null || _vm$getDocumentHead3$ === void 0 ? void 0 : (_vm$getDocumentHead3$2 = _vm$getDocumentHead3$[0]) === null || _vm$getDocumentHead3$2 === void 0 ? void 0 : _vm$getDocumentHead3$2.name))]) : _c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.getDocumentHead.getSupplier,
      "options": _vm.supplierList,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Выберите поставщика",
      "select_name": "supplier_select"
    },
    on: {
      "change": _vm.changeSupplier
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "select-options-description"
        }, [_c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v("ИНН:" + _vm._s(item === null || item === void 0 ? void 0 : item.inn))]), _c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v("КПП:" + _vm._s(item === null || item === void 0 ? void 0 : item.kpp))])])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "form-document-options__input-block"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Организация")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead4 = _vm.getDocumentHead) === null || _vm$getDocumentHead4 === void 0 ? void 0 : (_vm$getDocumentHead4$ = _vm$getDocumentHead4.getEntity) === null || _vm$getDocumentHead4$ === void 0 ? void 0 : (_vm$getDocumentHead4$2 = _vm$getDocumentHead4$[0]) === null || _vm$getDocumentHead4$2 === void 0 ? void 0 : _vm$getDocumentHead4$2.name))]) : _c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.getDocumentHead.getEntity,
      "options": _vm.entityList,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Выберите организацию",
      "select_name": "entity_select"
    },
    on: {
      "change": _vm.changeEntity
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "select-options-description"
        }, [_c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v("ИНН:" + _vm._s(item === null || item === void 0 ? void 0 : item.inn))]), _c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v("КПП:" + _vm._s(item === null || item === void 0 ? void 0 : item.kpp))])])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "form-document-options__input-block"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Магазин")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead5 = _vm.getDocumentHead) === null || _vm$getDocumentHead5 === void 0 ? void 0 : (_vm$getDocumentHead5$ = _vm$getDocumentHead5.getBranch) === null || _vm$getDocumentHead5$ === void 0 ? void 0 : (_vm$getDocumentHead5$2 = _vm$getDocumentHead5$[0]) === null || _vm$getDocumentHead5$2 === void 0 ? void 0 : _vm$getDocumentHead5$2.name))]) : _c('div', {
    staticClass: "segments-head__select-item"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.getDocumentHead.getBranch,
      "options": _vm.branchList,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Выберите магазин",
      "select_name": "branch_select"
    },
    on: {
      "change": _vm.changeBranch
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "form-document-options__input-block"
  }, [_c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Наименование")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead6 = _vm.getDocumentHead) === null || _vm$getDocumentHead6 === void 0 ? void 0 : _vm$getDocumentHead6.getName))]) : _c('e-input', {
    staticClass: "form-document-options__input",
    attrs: {
      "value": (_vm$getDocumentHead7 = _vm.getDocumentHead) === null || _vm$getDocumentHead7 === void 0 ? void 0 : _vm$getDocumentHead7.getName,
      "placeholder": "Введите наименование контракта",
      "type": "text"
    },
    on: {
      "change": _vm.changeNameContract
    }
  })], 1), _c('label', {
    staticClass: "form-document-options__label"
  }, [_vm._v("Период действия")]), _vm.getDocumentHead.fixed ? _c('div', [_vm._v(_vm._s((_vm$getDocumentHead8 = _vm.getDocumentHead) === null || _vm$getDocumentHead8 === void 0 ? void 0 : _vm$getDocumentHead8.getPeriod))]) : _c('div', {
    staticClass: "form-document-options__date-range"
  }, [_c('date-picker', {
    staticClass: "elm-calendar hidden-picker",
    attrs: {
      "clearable": false,
      "type": "daterange",
      "open": _vm.datepicker_validity_open,
      "editable": false,
      "format": "dd.MM.yyyy",
      "placeholder": "Рабочий период"
    },
    on: {
      "on-change": function ($event) {
        return _vm.setPeriodValidity({
          period: 'custom'
        });
      },
      "on-clickoutside": _vm.clickedOutsideValidity
    },
    model: {
      value: _vm.filter_date_validity,
      callback: function ($$v) {
        _vm.filter_date_validity = $$v;
      },
      expression: "filter_date_validity"
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none",
      "disabled": _vm.datepicker_validity_open
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "form-document-options__date-range-btn"
        }, [_c('span', {
          staticStyle: {
            "color": "black"
          }
        }, [_vm._v(_vm._s(_vm.getDocumentHead.getPeriod))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/calendar-dark.svg",
            "alt": "calendar"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriodValidity({
          period: null
        });
      }
    }
  }, [_vm._v(" Бессрочный ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.datepicker_validity_open = true;
      }
    }
  }, [_vm._v(" Указать период ")])], 1)], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }