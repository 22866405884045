var render = function render(){
  var _vm$getDocumentHead, _vm$getDocumentHead2, _vm$search;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-block"
  }, [_c('change-new-price-modal', {
    attrs: {
      "head": (_vm$getDocumentHead = _vm.getDocumentHead) === null || _vm$getDocumentHead === void 0 ? void 0 : _vm$getDocumentHead.id,
      "all_selected": _vm.select_all,
      "selected": _vm.selected
    },
    on: {
      "mass_update_contract_price": _vm.massUpdateContractSpec
    }
  }), _c('change-new-price-date-modal', {
    attrs: {
      "head": (_vm$getDocumentHead2 = _vm.getDocumentHead) === null || _vm$getDocumentHead2 === void 0 ? void 0 : _vm$getDocumentHead2.id,
      "all_selected": _vm.select_all,
      "selected": _vm.selected
    },
    on: {
      "mass_update_contract_date": _vm.massUpdateContractSpec
    }
  }), _c('price-history-modal', {
    attrs: {
      "item_id": _vm.itemId
    }
  }), _c('mass-add-product', {
    on: {
      "add_products": _vm.createSpec
    }
  }), _c('posting-document-warning-modal', {
    on: {
      "remove_warning-spec": _vm.removeWarningSpec,
      "save_as_draft": _vm.saveAsDraft
    }
  }), _c('product-movement-modal', {
    attrs: {
      "product": _vm.getProduct
    }
  }), _c('div', {
    staticClass: "table-block__content"
  }, [_c('div', {
    staticClass: "search-block"
  }, [_c('div', {
    staticClass: "pos-r"
  }, [_c('img', {
    staticClass: "search-block__img",
    attrs: {
      "src": "/img/icons/contract/search.svg",
      "alt": "search"
    }
  }), _c('b-form-input', {
    staticClass: "search-block__input",
    attrs: {
      "value": _vm.search,
      "type": "text",
      "placeholder": "Поиск"
    },
    on: {
      "change": _vm.handlerSearch
    }
  }), ((_vm$search = _vm.search) === null || _vm$search === void 0 ? void 0 : _vm$search.length) > 0 ? _c('b-button', {
    staticClass: "search-block__btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('div', {
    staticClass: "table"
  }, [_c('resizable-table', {
    ref: "table_contract",
    staticClass: "table-contract",
    attrs: {
      "table_name": "table_contract",
      "show_product_search": true,
      "inside_card": false,
      "items": _vm.getSpecList,
      "default_fields": _vm.fields,
      "empty_text": "Пусто",
      "resizable": true,
      "busy": _vm.isBusy
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item === null || item === void 0 ? void 0 : item.id);
            }
          }
        })];
      }
    }, {
      key: "body_order",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.order) + " ")];
      }
    }, {
      key: "body_name",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('product-spec-info', {
          attrs: {
            "product": item === null || item === void 0 ? void 0 : item.product
          }
        })];
      }
    }, {
      key: "body_future_date",
      fn: function (_ref4) {
        var _vm$getDocumentHead3;

        var item = _ref4.item;
        return [_c('date-picker', {
          ref: item.id + 'future_date',
          staticClass: "date-input",
          attrs: {
            "id": "work_period",
            "clearable": false,
            "disabled": (_vm$getDocumentHead3 = _vm.getDocumentHead) === null || _vm$getDocumentHead3 === void 0 ? void 0 : _vm$getDocumentHead3.fixed,
            "placeholder": "Выберите дату",
            "value": item === null || item === void 0 ? void 0 : item.getFutureBegin,
            "format": "dd.MM.yyyy",
            "transfer": ""
          },
          on: {
            "on-change": function (value) {
              return _vm.setDate(value, item);
            }
          }
        })];
      }
    }, {
      key: "body_future_prices",
      fn: function (_ref5) {
        var _vm$getDocumentHead4;

        var item = _ref5.item;
        return [_c('table-row-input', {
          ref: item.id + 'future_prices',
          staticClass: "future-prices",
          attrs: {
            "value": item === null || item === void 0 ? void 0 : item.getFuturePrice,
            "fixed": (_vm$getDocumentHead4 = _vm.getDocumentHead) === null || _vm$getDocumentHead4 === void 0 ? void 0 : _vm$getDocumentHead4.fixed,
            "input_id": item.id + 'future_prices',
            "symbol": "₽"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, item);
            },
            "input": function (val) {
              return _vm.setPrice(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_begin",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(_vm._s(_vm.convertDate(item === null || item === void 0 ? void 0 : item.begin)))])];
      }
    }, {
      key: "body_price",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.price))])];
      }
    }, {
      key: "body_reason",
      fn: function (_ref8) {
        var _item$reason, _item$reason2, _item$reason3;

        var item = _ref8.item;
        return [_c('div', {
          staticClass: "reason-containet"
        }, [_c('div', {
          staticClass: "reason-blok",
          class: {
            'reason-show': _vm.isReasonShow
          }
        }, _vm._l(_vm.parsTextForLink(item === null || item === void 0 ? void 0 : (_item$reason = item.reason) === null || _item$reason === void 0 ? void 0 : _item$reason.text, item === null || item === void 0 ? void 0 : (_item$reason2 = item.reason) === null || _item$reason2 === void 0 ? void 0 : _item$reason2.params), function (el, index) {
          return _c('div', {
            key: index,
            class: {
              'reason-link': el.link
            },
            on: {
              "click": function ($event) {
                el.link ? _vm.goLink(el === null || el === void 0 ? void 0 : el.params) : null;
              }
            }
          }, [_vm._v(" " + _vm._s(el.text) + " ")]);
        }), 0), item !== null && item !== void 0 && (_item$reason3 = item.reason) !== null && _item$reason3 !== void 0 && _item$reason3.text ? _c('img', {
          staticClass: "ml-2 reason-img",
          class: {
            'reason-img-active': _vm.isReasonShow
          },
          attrs: {
            "src": "/img/icons/contract/arrow-up.svg",
            "alt": "arrow-menu"
          },
          on: {
            "click": _vm.showReasonText
          }
        }) : _vm._e()])];
      }
    }, {
      key: "productSearch",
      fn: function () {
        return [_c('div', {
          staticClass: "product-search-block"
        }, [!_vm.getDocumentHead.fixed && _vm.scrollExist ? _c('product-search', {
          staticClass: "ml-2 product-search",
          staticStyle: {
            "width": "250px"
          },
          attrs: {
            "document_head": _vm.getDocumentHead.id,
            "document": _vm.getDocumentHead,
            "show_add_button": true,
            "document_type": "contract"
          },
          on: {
            "select_product": _vm.select_product
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('custom-navbar', {
    staticClass: "table-navbar",
    attrs: {
      "items": _vm.selected,
      "item_name": "Позиция"
    },
    on: {
      "clear": _vm.clearSelected
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        var _vm$getDocumentHead5, _vm$getDocumentHead6;

        return [!((_vm$getDocumentHead5 = _vm.getDocumentHead) !== null && _vm$getDocumentHead5 !== void 0 && _vm$getDocumentHead5.fixed) ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.deleteSpecItem
          }
        }, [_vm._v(" Удалить ")]) : _vm._e(), !((_vm$getDocumentHead6 = _vm.getDocumentHead) !== null && _vm$getDocumentHead6 !== void 0 && _vm$getDocumentHead6.fixed) ? _c('b-dropdown', {
          staticClass: "navbar-dropdown",
          attrs: {
            "dropup": "",
            "no-caret": "",
            "variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_vm._v("Действия")];
            },
            proxy: true
          }], null, false, 4277375889)
        }, [_c('b-dropdown-item', {
          on: {
            "click": _vm.openChangeNewPriceModal
          }
        }, [_c('div', [_vm._v("Изменить новую цену")])]), _c('b-dropdown-item', {
          on: {
            "click": _vm.openChangeNewPriceDateModal
          }
        }, [_c('div', [_vm._v("Изменить дату новой цены")])])], 1) : _vm._e(), _vm.selected.length === 1 ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.openPriceHistoryModal
          }
        }, [_vm._v("История ")]) : _vm._e(), _vm.selected.length === 1 ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.showProductMovement
          }
        }, [_vm._v(" Товародвижение ")]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }