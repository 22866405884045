import { ProductModel } from '@/models/product.model'
import { FuturePriceModel } from '@/models/future-price.model'
export class ContractSpecModel {
  constructor(request = {}) {
    this.id = request?.id
    this.reason = request?.reason
    this.order = request?.order
    this.price = request?.price
    this.begin = request?.begin ? new Date(request?.begin) : new Date()
    this.product = new ProductModel(request?.product)
    this.future_prices = request?.future_prices?.map((el) => new FuturePriceModel(el)) ?? [
      new FuturePriceModel({
        price: 0,
        begin: new Date()
      })
    ]
  }

  get getFuturePrice() {
    return this.future_prices[0].getPrice
  }

  get getFutureBegin() {
    return this.future_prices[0].getBegin
  }

  get inputContractSpec() {
    return {
      id: this.id,
      price: this.future_prices[0].getPrice,
      begin: this.future_prices[0].getBegin,
      order: 0
    }
  }

  setFutureBegin(date) {
    this.future_prices[0].setBegin(date)
  }

  setFuturePrice(price) {
    this.future_prices[0].setPrice(price)
  }
}
